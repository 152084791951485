import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Accordion, Card } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import './displayPosts.css';

const DisplayPosts = () => {
  // stores the posts to be displayed
  const [posts, setPosts] = useState([]);

  // stores the contents of any new comments being composed
  const [newComments, setNewComments] = useState({});
  // stores the users of any new comments being composed
  const [newUsers, setNewUsers] = useState({});

  // update the posts being displayed
  const getPosts = () => {
    axios
      .get('https://serverless-api.sidwan02.workers.dev/posts')
      .then((response) => {
        setPosts(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // on startup, get the posts for the initial display
  useEffect(() => {
    getPosts();
  }, []);

  // error check fields before posting new comment
  const handleAddComment = (e) => {
    e.preventDefault();

    const postTimestamp = e.target.parentElement.getAttribute('class');

    const username = newUsers[postTimestamp];
    const comment = newComments[postTimestamp];

    const cleaned_username = cleanInput(username);
    const cleaned_comment = cleanInput(comment);

    if (cleaned_username === '' || cleaned_username === undefined) {
      alert('Error: Username cannot be empty');
    } else if (cleaned_comment === '' || cleaned_comment === undefined) {
      alert('Error: Comment cannot be empty');
    } else {
      requestAddComment(postTimestamp, username, comment);
    }
  };

  // update a new comment's username to reflect changed input
  const handleChangeUsername = (username, timestamp) => {
    setNewUsers((category) => ({
      ...category,
      [timestamp]: username,
    }));
  };

  // update a new comment's content to reflect changed input
  const handleChangeComment = (comment, timestamp) => {
    setNewComments((category) => ({
      ...category,
      [timestamp]: comment,
    }));
  };

  // toggles the accordion containing the form to add a new comment
  function ToggleAddComment({ children, eventKey }) {
    const toggleAccordion = useAccordionButton(eventKey);

    return (
      <button
        type="button"
        style={{
          backgroundColor: 'rgba(145, 233, 164, 0.301)',
          border: '1px solid black',
          borderRadius: '5px',
        }}
        onClick={toggleAccordion}
      >
        {children}
      </button>
    );
  }

  // update the new comment in KV
  const requestAddComment = (postTimestamp, username, comment) => {
    const toSend = {
      postTimestamp: postTimestamp,
      comment: comment,
      username: username,
      commentTimestamp: Date().toLocaleString(),
    };

    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    axios
      .post(
        'https://serverless-api.sidwan02.workers.dev/comment',
        toSend,
        config
      )
      .then(() => {
        handleChangeUsername('', postTimestamp);
        handleChangeComment('', postTimestamp);
        getPosts();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // stores the fields of a new post
  const [state, setState] = useState({ title: '', username: '', content: '' });

  // update a new post's content to reflect changed input
  const handleInputChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // update the new post in KV
  const requestAddPost = (title, username, content) => {
    const toSend = {
      timestamp: Date().toLocaleString(),
      title: title,
      username: username,
      content: content,
      comments: [],
    };

    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    axios
      .post('https://serverless-api.sidwan02.workers.dev/posts', toSend, config)
      .then(() => {
        handleInputChange('title', '');
        handleInputChange('username', '');
        handleInputChange('content', '');
        getPosts();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // removes all whitespace from any string input
  const cleanInput = (str) => {
    return typeof str === 'string' ? str.split(/\s+/).join('') : str;
  };

  // error check fields before posting new post
  const handleAddPost = (e) => {
    e.preventDefault();

    const cleaned_title = cleanInput(state.title);
    const cleaned_username = cleanInput(state.username);
    const cleaned_content = cleanInput(state.content);

    if (cleaned_title === '') {
      alert('Error: Title cannot be empty');
    } else if (cleaned_username === '') {
      alert('Error: Username cannot be empty');
    } else if (cleaned_content === '') {
      alert('Error: Content cannot be empty');
    } else {
      requestAddPost(state.title, state.username, state.content);
    }
  };

  // toggles the accordion containing the form to add a new post
  function ToggleAddPost({ children, eventKey }) {
    const toggleAccordion = useAccordionButton(eventKey);

    return (
      <button
        type="button"
        style={{
          backgroundColor: 'rgba(233, 188, 145, 0.301)',
          border: '1px solid black',
          borderRadius: '5px',
        }}
        onClick={toggleAccordion}
      >
        {children}
      </button>
    );
  }

  return (
    <div>
      <div id="header">
        <h1>Posts</h1>
      </div>

      <Accordion defaultActiveKey="0" flush>
        <Card id="addPostAccordion">
          <Card.Header id="accordian-header">
            <ToggleAddPost eventKey="1">Add Post</ToggleAddPost>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <form onSubmit={handleAddPost} id={'newPostForm'}>
                <label>
                  Title:
                  <br />
                  <input
                    className="input"
                    id="titleinput"
                    name="title"
                    type="text"
                    value={state.title}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                  />
                </label>

                <br />
                <br />

                <label>
                  Username:
                  <br />
                  <input
                    className="input"
                    id="usernameInput"
                    name="username"
                    type="text"
                    value={state.username}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                  />
                </label>

                <br />
                <br />

                <label>
                  Content:
                  <br />
                  <textarea
                    id="postContent"
                    name="content"
                    value={state.content}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                  />
                </label>

                <br />

                <input type="submit" value="Submit" />
              </form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <div id="postsContainer">
        {posts.map((post) => (
          <div key={post.timestamp} id="singlePost">
            <div id="titleContainer">{post.title}</div>

            <div id="usernameTimestampContainer">
              <div id="usernameContainer">{post.username}</div>
              <div id="timestampContainer">{post.timestamp}</div>
            </div>

            <div id="contentContainer" className="postContainer">
              {post.content.split('\n').map((line) => (
                <p>{line}</p>
              ))}
            </div>

            <br />

            <Accordion defaultActiveKey="0" flush>
              <Card id="addCommentAccordion">
                <Card.Header>
                  <ToggleAddComment eventKey="1">Add Comment</ToggleAddComment>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div id="commentForm" className={post.timestamp}>
                      <form onSubmit={handleAddComment}>
                        <label>
                          Username:
                          <br />
                          <input
                            name="username"
                            className="input"
                            type="text"
                            value={
                              newUsers[post.timestamp] === undefined
                                ? ''
                                : newUsers[post.timestamp]
                            }
                            onChange={(e) =>
                              handleChangeUsername(
                                e.target.value,
                                post.timestamp
                              )
                            }
                          />
                        </label>

                        <br />
                        <br />

                        <label>
                          Comment:
                          <br />
                          <textarea
                            name="comment"
                            id="commentContent"
                            className="input"
                            value={newComments[post.timestamp]}
                            onChange={(e) =>
                              handleChangeComment(
                                e.target.value,
                                post.timestamp
                              )
                            }
                          />
                        </label>

                        <br />

                        <input type="submit" value="Submit" />
                      </form>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            <div id="lineSeparated">Comments</div>

            <div id={'commentsContainer'}>
              {post.comments.map((comment) => (
                <div
                  key={comment.timestamp}
                  id="singleComment"
                  className={comment.timestamp}
                >
                  <div id="usernameTimestampContainer">
                    <div id="usernameContainer" className={'green-bg'}>
                      {comment.username}
                    </div>

                    <div id="timestampContainer" className={'green-bg'}>
                      {comment.timestamp}
                    </div>
                  </div>

                  <div id="contentContainer" className="commentContainer">
                    {comment.content.split('\n').map((line) => (
                      <p>{line}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DisplayPosts;
