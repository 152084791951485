import './App.css';
import { Router } from '@reach/router';

import DisplayPosts from './displayPosts.js';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <DisplayPosts path="/" />
    </Router>
  );
}

export default App;
